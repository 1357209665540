var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-upload", {
          attrs: { editable: _vm.editable, label: "MSDS 첨부파일" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }