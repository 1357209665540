<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-upload
        :editable="editable"
        label="MSDS 첨부파일">
      </c-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-msdsfile',
  props: {
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>